import { useUserSession } from './useUserSession';
import { useCountriesData } from '@utils/useCountriesData';
import { erpSystemTypes } from '@src/routes'; // Return true/false if it's China site user.

export var useChinaUser = function useChinaUser() {
  var _useUserSession = useUserSession(),
      userSession = _useUserSession.userSession;

  return userSession.country.toLocaleUpperCase() === 'CN';
};
export var useKoreaUser = function useKoreaUser() {
  var _useUserSession2 = useUserSession(),
      userSession = _useUserSession2.userSession;

  return userSession.country.toLocaleUpperCase() === 'KR';
};
export var useMexicoUser = function useMexicoUser() {
  var _useUserSession3 = useUserSession(),
      userSession = _useUserSession3.userSession;

  return userSession.country.toLocaleUpperCase() === 'MX';
};
export var useIndonesiaUser = function useIndonesiaUser() {
  var _useUserSession4 = useUserSession(),
      userSession = _useUserSession4.userSession;

  return userSession.country.toLocaleUpperCase() === 'ID';
};
export var useJapanUser = function useJapanUser() {
  var _useUserSession5 = useUserSession(),
      userSession = _useUserSession5.userSession;

  return userSession.country.toLocaleUpperCase() === 'JP';
};
export var useUsUer = function useUsUer() {
  var _useUserSession6 = useUserSession(),
      userSession = _useUserSession6.userSession;

  return userSession.country.toLocaleUpperCase() === 'US';
};
export var useCountryUser = function useCountryUser(countryCode) {
  var _useUserSession7 = useUserSession(),
      userSession = _useUserSession7.userSession;

  return userSession.country.toLocaleUpperCase() === countryCode;
};
export var useIsIscalaCountry = function useIsIscalaCountry() {
  var _useUserSession8 = useUserSession(),
      userSession = _useUserSession8.userSession;

  var _useCountriesData = useCountriesData(),
      getCountryDetails = _useCountriesData.getCountryDetails;

  var userCountryInfo = getCountryDetails(userSession.country.toLocaleUpperCase());
  return userCountryInfo.erpSystemId === erpSystemTypes.Iscala;
};
export var useIsQrpCountry = function useIsQrpCountry() {
  var _useUserSession9 = useUserSession(),
      userSession = _useUserSession9.userSession;

  var _useCountriesData2 = useCountriesData(),
      getCountryDetails = _useCountriesData2.getCountryDetails;

  var userCountryInfo = getCountryDetails(userSession.country.toLocaleUpperCase());
  return userCountryInfo.erpSystemId === erpSystemTypes.Qrp;
};