import _defineProperty from "/builds/u5yGSXwH/8/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _toConsumableArray from "/builds/u5yGSXwH/8/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useRouter as useNextRouter } from 'next/router';
import { useUserSession } from './useUserSession';
import { isObject } from 'lodash';
export var appendRouterArgs = function appendRouterArgs(userSession, url, as, options) {
  var _userSession$language, _userSession$country;

  var language = (userSession === null || userSession === void 0 ? void 0 : (_userSession$language = userSession.language) === null || _userSession$language === void 0 ? void 0 : _userSession$language.toLowerCase()) || '';
  var country = (userSession === null || userSession === void 0 ? void 0 : (_userSession$country = userSession.country) === null || _userSession$country === void 0 ? void 0 : _userSession$country.toUpperCase()) || '';
  var href = isObject(url) ? url.pathname || '' : url; // Ignore routes that already have basename OR catchAll

  if (href.startsWith('/[country]/[language]') || href.startsWith('/[...rest]')) {
    return [url, as, options];
  }

  var urlPath = "/[country]/[language]".concat(isObject(url) ? url.pathname : url); // If the provided URL is a string, pass it through as a string so we do not mangle query params

  var urlArg = isObject(url) ? {
    pathname: urlPath,
    query: url.query
  } : urlPath;
  var asUrl = as || url;
  var asPath = "/".concat(country, "/").concat(language).concat(isObject(asUrl) ? asUrl.pathname : asUrl); // If the provided URL is a string, pass it through as a string so we do not mangle query params

  var asArg = isObject(asUrl) ? {
    pathname: asPath,
    query: asUrl.query
  } : asPath;
  return [urlArg, asArg, options];
};
export var useLocalizedRouter = function useLocalizedRouter() {
  // WARNING: This utility does not respond to changes of `userSession.country` or `userSession.language` if
  // the underlying cookie changes. The `appendRouterArgs` input is static, being that the `useLocalizedRouter`
  // module cannot be overwritten once invoked
  var _useUserSession = useUserSession(),
      userSession = _useUserSession.userSession;

  var router = useNextRouter();

  var push = function push() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return router.push.apply(router, _toConsumableArray(appendRouterArgs.apply(void 0, [userSession].concat(args))));
  };

  var replace = function replace() {
    for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    return router.replace.apply(router, _toConsumableArray(appendRouterArgs.apply(void 0, [userSession].concat(args))));
  };

  return _objectSpread(_objectSpread({}, router), {}, {
    replace: replace,
    push: push
  });
};
export default useLocalizedRouter;